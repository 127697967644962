@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.white-img {
    filter: brightness(0) invert(1);
}

.bg-conic-gradient {
    background-image: conic-gradient(from 225deg, #8450ff, #41b9ff, #8450ff, #41b9ff, #8450ff);

}
html, body {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    scroll-behavior: smooth;
    font-family: "Inter", sans-serif;
}


#root{
    height: 100%;
}

.toast {
    transition: opacity 0.5s ease-in-out;
    opacity: 0; /* Start with hidden toast */
  }
  
  .toast-show {
    opacity: 1; /* Fade in */
  }
  
  .toast-hide {
    opacity: 0; /* Fade out */
  }

  .sunshine {
    width: 100%;
    height: 550px;
    background: linear-gradient(to top, #0E0C15 30%, #8176D2 300%);
    filter: blur(30px);
    transform: rotate(-0deg);
    position: absolute;
    margin-top: 50px;
  

}



.scroller {
  max-width: 2000px;
  margin-top: 100px;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 40%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

/* general styles */




